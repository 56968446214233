import ARService from "@/services/ARService";
import store from "@/store";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

const CREDIT_MEMO_AR_TYPE = "CM";

export function useRefundAccountsReceivables(service: ARService) {
  async function postARRefund(order: any) {
    order.type = CREDIT_MEMO_AR_TYPE;

    try {
      const resp = await service.postInvoice(order);
      return resp;
    } catch (error: any) {
      store.dispatch(ADD_ERROR_NOTIFICATION, {
        message: `Error Posting Refund. ${error.error || error.message}.`,
      });
    }
  }

  return { postARRefund };
}
