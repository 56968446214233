
import { defineComponent } from "vue";
import PrinterSelectionDialog from "@/components/Pos/PrinterSelectionDialog.vue";
import MessageBox from "@/components/MessageBox.vue";
import SalesOrderService from "@/services/SalesService";
import { mapActions, mapGetters } from "vuex";

import Utils from "@/utility/utils";

const salesOrderService = new SalesOrderService();

export default defineComponent({
  name: "PrintPickTicket",
  components: {
    PrinterSelectionDialog,
    MessageBox,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    salesOrder: {
      type: Object,
    },
  },
  data() {
    return {
      saveDefaultPrinter: false,
      showConfirmPrintPickTicketDialog: false,
      showPickTicketPrinterDialog: false,
    };
  },
  emits: ["handleClose"],
  computed: {
    ...mapGetters({
      getClient: "pos/getClient",
      getPrinterQueues: "printControl/getPrinterQueues",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
    }),
  },
  methods: {
    ...mapActions({
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
    }),
    handlePrintPickTicket(data: any) {
      this.showPickTicketPrinterDialog = false;

      // set the default printer if option is selected
      if (data.saveDefaultPrinter) {
        // we may want to support multiple default printers in the future
        this.setDefaultPickTicketPrinter(data.selectedPrinter);
      }
      // print the pick ticket for the order.
      salesOrderService.printPickTicket(
        this.salesOrder?.so_id,
        this.getClient,
        data.selectedPrinter,
      );
      this.cancel();
    },
    cancel() {
      this.$emit("handleClose");
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (Utils.booleanCheck(this.salesOrder?.custom_fields?.printed_flag)) {
          this.showConfirmPrintPickTicketDialog = true;
        } else {
          this.showPickTicketPrinterDialog = true;
        }
      }
    },
  },
});
