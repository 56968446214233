import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_POSCartDialog = _resolveComponent("POSCartDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Button, {
      label: "Cart",
      class: "",
      icon: "pi pi-shopping-cart",
      iconPos: "left",
      onClick: _ctx.handleOpenDialog
    }, null, 8, ["onClick"]),
    _createVNode(_component_POSCartDialog, {
      ref: "cartDialog",
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
      selectedLineItemIndex: _ctx.selectedLineItemIndex,
      onOnCloseDialog: _ctx.posCloseCartDialog,
      lineItems: _ctx.getLineItems,
      onOnSave: _ctx.saveSalesOrder,
      getLineItemChanges: _ctx.getLineItemChanges,
      lineItemCustomFields: _ctx.lineItemCustomFields,
      allowEditing: _ctx.allowEditing
    }, null, 8, ["modelValue", "selectedLineItemIndex", "onOnCloseDialog", "lineItems", "onOnSave", "getLineItemChanges", "lineItemCustomFields", "allowEditing"])
  ]))
}