import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";
export default class AuthenticationService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async authenticate(payload: any) {
    return ClientAPI.Post(`login`, payload);
  }
}
