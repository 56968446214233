import Utils from "@/utility/utils";

export interface FdictResponse {
  fdict_items: Array<Fdict>;
}
export interface FdictState {
  fdicts: Array<Fdict>;
}

export interface Fdict {
  file_name: string;
  field_no_items: Array<Field>;
  correl_field_no_items: Array<Correlative>;
}

export interface Field {
  field_no: string;
  control_field?: string;
  conv?: string;
  dict_name: string;
  multi_line?: string;
  len?: string;
  required?: string;
  reference_file?: string;
  desc_items: Array<Desc>;
  web_required?: string;
  valid_value_items?: Array<ValidEntry>;
  default_selection?: string;
  custom_label?: string;
  custom_field?: string;
  display_name?: string;
  json_name: string;
  boolean?: string;
  data_only?: string;
  read_only?: string;
}

export function isFieldReadOnly(field: Field): boolean {
  return Utils.booleanCheck(field?.read_only);
}

export interface ValidEntry {
  valid_value: string;
  valid_desc: string;
}

export interface Desc {
  desc: string;
}

export interface Correlative {
  correl_field_no?: string;
  correl_desc?: string;
  corr_ctrl_fld?: string;
  correl_dict_id: string;
  correlative?: string;
  correl_just?: string;
  correl_len?: string;
  correl_rw_ok?: string;
}
