
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import POSCartDialog from "./POSCartDialog.vue";
import Button from "primevue/button";
import EventBus, { AppEvents } from "@/utility/EventBus";
import { isEqual } from "lodash";

import { Fdict, Field } from "@/types/fdict";
import { FDICT_SO } from "@/utility/fdicts/so";

export default defineComponent({
  components: {
    POSCartDialog,
    Button,
  },
  emits: ["onRemovePartClick", "closePartDialog"],
  props: {
    selectedLineItemIndex: {
      type: Number,
      default: -1,
    },
    lineItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getSalesOrder: "pos/getSalesOrder",
      getCustomer: "pos/getCustomer",
      getField: "fdict/getField",
      getRacks: "pos/getRacks",
      getHidePosCustomLineItems: "pos/getHidePosCustomLineItems",
      posMaintainCartSort: "mrkControl/posMaintainCartSort",
      getCustomFields: "fdict/customFields",
    }),
    getLineItems(): any[] {
      return this.getSalesOrder.lis_items.map((lineItem: any) => {
        return {
          ...lineItem,
          key: lineItem.li_parts,
        };
      });
    },
    lineItemCustomFields(): any[] {
      if (this.getHidePosCustomLineItems) {
        return [];
      }

      if (this.salesOrderFDictItems?.field_no_items) {
        return this.getCustomFields("SO", FDICT_SO.LIS);
      }

      return [];
    },
    allowEditing(): boolean {
      return this.$route.fullPath.includes("pos/parts");
    },
  },
  async created() {
    this.salesOrderFDictItems = await this.getFdicts("SO");
  },
  data() {
    return {
      selectedLineItems: [] as any[],
      showRemoveConfirm: false,
      indexToRemove: -1,
      showDialog: false,
      salesOrderFDictItems: {} as Fdict,
    };
  },
  methods: {
    ...mapActions({
      validateSalesOrder: "pos/validateSalesOrder",
      addNotification: "notification/add",
      getFdicts: "fdict/fetchFdict",
      removeRacksById: "pos/removeRacksById",
    }),
    handleOpenDialog() {
      this.showDialog = true;
      this.selectedLineItems = this.lineItems;
    },
    posCloseCartDialog() {
      this.$emit("closePartDialog");
      EventBus.emit(AppEvents.PosFocusPartSearch, true);
    },
    saveSalesOrder(newLineItems: any) {
      const originalOrder = JSON.parse(JSON.stringify(this.getSalesOrder));

      this.getSalesOrder.lis_items.forEach((element: any, index: number) => {
        if (
          !newLineItems.find((item: any) => item.li_parts === element.li_parts)
        ) {
          this.removePart(null, index);
        }
      });

      newLineItems.forEach((element: any) => {
        const originalLineItem = this.getSalesOrder.lis_items.find(
          (item: any) => item.lis === element.lis,
        );
        if (
          originalLineItem.li_order_qtys !== element.li_order_qtys &&
          element.li_sched_dates_items?.length > 0
        ) {
          const difference =
            element.li_order_qtys - originalLineItem.li_order_qtys;
          element.li_sched_dates_items[0].li_sched_qtys =
            +element.li_sched_dates_items[0].li_sched_qtys + difference + "";
        }
      });

      this.getSalesOrder.lis_items = newLineItems;

      this.validateSalesOrder({
        oldSalesOrder: originalOrder,
        metaData: {
          validate_only: "Y",
          validate_reason: "LIS_ITEM",
        },
      });

      if (this.getSalesOrder.lis_items === newLineItems) {
        this.addNotification({
          message: `Cart changes have been saved`,
          type: "success",
        });
      } else {
        this.addNotification({
          message: `There was a problem updating the cart`,
          type: "error",
        });
      }
    },
    getLineItemChanges(oldLineItem: any, newLineItem: any) {
      let changes = [];
      if (+newLineItem.li_order_qtys !== +oldLineItem?.li_order_qtys) {
        changes.push("Quantity");
      }
      if (+newLineItem.li_prices !== +oldLineItem?.li_prices) {
        changes.push("Price");
      }
      if (newLineItem.wrap_desc !== oldLineItem?.wrap_desc) {
        changes.push("Description");
      }
      if (newLineItem.li_notes !== oldLineItem?.li_notes) {
        changes.push("Notes");
      }

      // Check for changes in custom_fields
      if (oldLineItem) {
        if (newLineItem.custom_fields && oldLineItem.custom_fields) {
          Object.keys(newLineItem.custom_fields).forEach((key) => {
            if (
              !isEqual(
                newLineItem.custom_fields[key],
                oldLineItem.custom_fields[key],
              )
            ) {
              changes.push(key.charAt(0).toUpperCase() + key.slice(1));
            }
          });
        }
      }
      return changes;
    },
    removePart(event: any, indexToRemove: number) {
      if (this.getSalesOrder.lis_items?.length > indexToRemove) {
        const part = this.getSalesOrder.lis_items[indexToRemove];
        const hasRackPart = this.getRacks.find((rack: any) => {
          return rack.part_id === part.li_parts;
        });
        if (hasRackPart) {
          this.removeRacksById(part.li_parts);
        }
      }
      this.$emit("onRemovePartClick", event, indexToRemove);
    },
  },
});
