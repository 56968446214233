
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";

import AuthenticationService from "@/services/AuthenticationService";

const authService = new AuthenticationService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  components: {
    Dialog,
    InputText,
    Password,
    Button,
  },
  emits: ["onAuthenticate"],
  data() {
    return {
      admin_user: "",
      admin_pwd: "",
      adminAccess: false,
      isLoading: false,
      show: false,
    };
  },
  props: {
    requireAdminAccess: {
      type: Boolean,
      default: false,
    },
    showAdminLogin: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Additional Access Required",
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    isAuthenticateDisabled(): boolean {
      return (
        this.admin_user === null ||
        this.admin_user === "" ||
        this.admin_pwd === null ||
        this.admin_pwd === ""
      );
    },
  },
  methods: {
    authenticate() {
      if (
        this.admin_user === null ||
        this.admin_user === "" ||
        this.admin_pwd === null ||
        this.admin_pwd === ""
      ) {
        return;
      }
      this.isLoading = true;
      authService
        .authenticate({
          Email: this.admin_user,
          Password: this.admin_pwd,
          EncryptedPassword: this.admin_pwd,
          Client: this.getClient,
          Action: "Login",
        })
        .then((response: any) => {
          if (response.user) {
            if (parseFloat(response.user.security_level) >= 80) {
              this.adminAccess = true;
            }
            if (
              (this.requireAdminAccess && this.adminAccess) ||
              !this.requireAdminAccess
            ) {
              this.admin_pwd = "";
              this.admin_user = "";
              this.$emit("onAuthenticate", response.user);
            } else {
              const notification = {
                message: `User does not have the neccessary admin rights to configure the register .`,
                type: "error",
              };
              store.dispatch("notification/add", notification);
            }
          } else {
            let err = "";
            if (response.error) {
              err = response.error;
            }
            const notification = {
              message: `User could not be authenticated. ${err}`,
              type: "error",
            };
            store.dispatch("notification/add", notification);
          }
        })
        .catch((error: any) => {
          const notification = {
            message: `User could not be authenticated. ${error.message}`,
            type: "error",
          };
          store.dispatch("notification/add", notification);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    showAdminLogin: {
      immediate: true,
      handler(val) {
        this.show = val;
      },
    },
  },
});
