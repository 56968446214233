<template>
  <Payment
    :showAddressInput="showAddressInput"
    :getIframeUrl="getIframeUrl"
    :creditCardData="creditCards"
    @creditCardChanged="handleCreditCardChanged"
    @save-payment="save"
    @delete-item="deleteItem"
  />
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import CustomerService from "@/services/CustomerService";
const customerService = new CustomerService();
import iframeResize from "iframe-resizer/js/iframeResizer";
import Payment from "../UI/Table/Payment.vue";

export default defineComponent({
  name: "CreditCards",
  props: {
    index: Number,
  },
  components: {
    Payment,
  },
  directives: {
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
    this.handleCreditCardData();
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getUser: "session/getUser",
      getClient: "session/getClient",
      getIframeUrl: "payment/getIframeUrl",
    }),
  },
  data() {
    return {
      creditCard: {},
      deleteItemDialog: false,
      token: "",
      success: false,
      displayIframe: false,
      tokenMessage: "",
      creditCards: [],
      showAddressInput: true,
    };
  },
  methods: {
    ...mapActions({
      setCust: "pos/setCustomer",
      addNotification: "notification/add",
      fetchPaymentInfo: "payment/fetchPaymentInfo",
    }),
    handleCreditCardChanged(credit) {
      this.creditCard = credit;
    },
    handleCreditCardData() {
      // populate the payment iframe url if not already populated
      if (this.getIframeUrl == null || this.getIframeUrl == "") {
        this.fetchPaymentInfo({
          option: "IFRAMEURL ACHFLAG CCADRFLAG",
          Client: this.getClient,
        });
      }
      this.creditCards = this.getCustomer.credit_card_no_items;
    },
    deleteItem() {
      const oldCust = this.getCustomer;
      const newCreditCards = this.creditCards.filter((item) => {
        return item.credit_card_id !== this.creditCard.credit_card_id;
      });
      let newCust = JSON.parse(JSON.stringify(this.getCustomer));
      newCust.credit_card_no_items = newCreditCards;
      customerService
        .updateCustomer(
          this.getCustomer.cust_id,
          oldCust,
          newCust,
          this.getClient,
          this.getUser.user_id,
        )
        .then((response) => {
          if (response.response.status === "success") {
            this.creditCards = newCreditCards;
            const custs = { cust_items: [newCust] };
            this.setCust(custs);
          } else {
            const notification = {
              message: `Could not remove payment method. ${response.response.error}.`,
              type: "error",
            };
            this.addNotification(notification);
          }
        })
        .catch((error) => {
          const notification = {
            message: `Could not remove payment method.`,
            type: "error",
          };
          this.addNotification(notification);
        });
    },
    isRequiredDataFilled() {
      return (
        this.creditCard.cardholder_name &&
        this.creditCard.cc_addr &&
        this.creditCard.cc_zip
      );
    },
    save(creditCard) {
      if (!this.isRequiredDataFilled()) {
        const notification = {
          message: `Missing input data or did not validate card. Please fill in all required fields.`,
          type: "error",
        };

        this.addNotification(notification);
        return;
      }
      const oldCust = this.getCustomer;
      let newCust = JSON.parse(JSON.stringify(this.getCustomer));
      (newCust.credit_card_no_items = [
        ...(this.getCustomer.credit_card_no_items || []),
        { ...creditCard },
      ]),
        customerService
          .updateCustomer(
            this.getCustomer.cust_id,
            oldCust,
            newCust,
            this.getClient,
            this.getUser.user_id,
          )
          .then((response) => {
            if (response.response.status === "success") {
              this.creditCards = newCust.credit_card_no_items;
              const custs = { cust_items: [newCust] };
              this.setCust(custs);
            } else {
              const notification = {
                message: `Could not add payment method. ${response.response.error}.`,
                type: "error",
              };
              this.addNotification(notification);
            }
          })
          .catch((error) => {
            const notification = {
              message: `Could not add payment method.`,
              type: "error",
            };
            this.addNotification(notification);
          });
    },
  },
});
</script>
