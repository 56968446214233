
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "OrderSummaryItemWrapper",
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    ...mapGetters({
      getLastItemChanged: "pos/getLastItemChanged",
    }),
  },

  emits: ["removePart"],

  methods: {
    focus() {
      this.isFocused = true;
      setTimeout(() => {
        this.isFocused = false;
      }, 1500);
    },
  },

  watch: {
    "part.li_order_qtys": {
      handler(newValue, oldValue) {
        this.focus();
      },
    },
    getLastItemChanged() {
      if (
        this.part.so_id === this.getLastItemChanged ||
        this.part.ar_id === this.getLastItemChanged
      ) {
        this.focus();
      }
    },
  },
});
