
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import CheckBox from "primevue/checkbox";

export default defineComponent({
  name: "PrinterSelectionDialog",
  components: {
    Dialog,
    Dropdown,
    CheckBox,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    printers: {
      type: Array,
      required: true,
    },
    defaultPrinter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDialog: this.visible,
      selectedPrinter: null as any | null,
      printerOptions: [] as any[],
      saveDefaultPrinter: false,
    };
  },
  emits: ["onPrintDialogPrint", "onPrintDialogCancel"],

  methods: {
    handlePrint() {
      this.$emit("onPrintDialogPrint", {
        selectedPrinter: this.selectedPrinter,
        saveDefaultPrinter: this.saveDefaultPrinter,
      });
      this.showDialog = false;
    },

    cancel() {
      this.showDialog = false;
      this.$emit("onPrintDialogCancel");
    },
  },
  created() {
    this.showDialog = this.visible;
    this.selectedPrinter = null;
  },
  updated() {
    this.showDialog = this.visible;
    this.printerOptions = this.printers;
    this.selectedPrinter = this.defaultPrinter;
  },
});
