import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_POSCartDialog = _resolveComponent("POSCartDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_POSCartDialog, {
      ref: "cartDialog",
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:visible', $event))),
      selectedLineItemIndex: _ctx.selectedLineItemIndex,
      onOnCloseDialog: _ctx.posCloseCartDialog,
      onOnRemovePartClick: _ctx.removePartClick,
      lineItems: _ctx.getLineItems,
      onOnSave: _ctx.processRefund,
      getLineItemChanges: _ctx.getLineItemChanges,
      lineItemCustomFields: _ctx.lineItemCustomFields,
      allowEditing: _ctx.allowEditing,
      showSaveButton: false,
      confirmAndCloseText: "Process Refund"
    }, null, 8, ["modelValue", "selectedLineItemIndex", "onOnCloseDialog", "onOnRemovePartClick", "lineItems", "onOnSave", "getLineItemChanges", "lineItemCustomFields", "allowEditing"])
  ]))
}