
import { defineComponent } from "vue";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Customer from "@/types/customer";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "RecentlyViewedCustomers",
  components: {
    Button,
    DataTable,
    Column,
  },
  props: {
    customers: Array as () => Array<Customer>,
    allowRemove: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      controlFieldValues: "fdict/controlFieldValues",
      fieldExists: "fdict/fieldExists",
    }),
    showServiceRep(): boolean {
      return this.fieldExists("CUST", "SERVICE.REP");
    },
  },
  data() {
    return {
      idFields: [],
    };
  },
  mounted() {
    this.idFields = this.controlFieldValues("CUST", "0");
  },
  methods: {
    ...mapActions({
      removeRecentlyViewedCustomer:
        "customerInquiry/removeRecentlyViewedCustomer",
    }),
    handleCustomersRowClick(e: any) {
      this.$emit("customer-table-row-click", e);
    },
    shippingAddress(customer: Customer) {
      return Utils.customerShippingAddress(customer);
    },
    removeCustomer(custId: string) {
      this.$emit("remove-customer", custId);
    },
    pageClick(e: any) {
      this.$emit("page-click", e);
    },
  },
});
