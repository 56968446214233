
import { defineComponent, PropType } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import LookupPartNumber from "../Sales/LineItems/LookupPartNumber.vue";
import MultiSelect from "primevue/multiselect";
import MessageBox from "@/components/MessageBox.vue";

import Utils from "@/utility/utils";
import { LisItems } from "@/types/salesorder";
import { Fdict, Field, isFieldReadOnly } from "@/types/fdict";

export default defineComponent({
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Tag,
    Textarea,
    InputNumber,
    LookupPartNumber,
    Calendar,
    Dropdown,
    MultiSelect,
    InputSwitch,
    MessageBox,
  },
  props: {
    class: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: String as () => "row" | "cell",
      default: null,
    },
    scrollHeight: {
      type: String,
      default: "55vh",
    },
    value: {
      type: Array,
      default: () => [] as any[],
    },
    removable: {
      type: Boolean,
      default: false,
    },
    editQtyDirectly: {
      type: Boolean,
      default: false,
    },
    partIdFields: {
      type: Array,
      default: () => [] as any[],
    },
    selectedLineItemIndex: {
      type: Number,
      default: -1,
    },
    lineItemCustomFields: {
      type: Array as PropType<Field[]>,
      default: () => [] as any[],
    },
    compareLineItem: {
      type: Function,
      required: true,
    },
    partNumberLabel: {
      type: String,
      default: "Part Number",
    },
  },
  inject: ["tableTitle"],
  data() {
    return {
      lineItems: [] as any[],
      editingItems: [] as any[],
      showLookupPartNumberDialog: false,
      selectedPart: "",
      filters: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      expandedRows: [] as Array<LisItems>,
      toggleShowCostColumn: false,
      tableTitle: this.tableTitle || "Shopping Cart",
      showRemoveConfirm: false,
      indexToRemove: -1,
    };
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getDisableUpdatingPartDescription:
        "mrkControl/disableUpdatingPartDescription",
      getPosShowPartCostColumn: "mrkControl/posShowPartCostColumn",
      getPosAllowNegativePrice: "mrkControl/posAllowNegativePrice",
      controlFieldValues: "fdict/controlFieldValues",
    }),
    getEditableLineItemFields(): Field[] {
      return this.lineItemCustomFields.filter(
        (field: Field) => field.data_only != "Y",
      );
    },
    getDataTableClass(): any {
      return {
        "p-datatable-sm": true,
        [this.class]: this.class,
      };
    },
    minimumPrice(): number {
      if (this.getPosAllowNegativePrice) {
        return Number.NEGATIVE_INFINITY;
      } else {
        return 0;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.setLineItemData();
      },
    },
    selectedLineItemIndex: {
      immediate: true,
      handler(newVal) {
        this.editingItems = [];
        this.setLineItemData();
      },
    },
    lineItems: {
      handler(newVal) {
        const newLineItems = JSON.parse(JSON.stringify(newVal));

        newLineItems.forEach((lineItem: any) => {
          lineItem.li_order_qtys = lineItem.li_order_qtys?.toString();
          lineItem.li_prices = lineItem.li_prices?.toString();
        });
        this.$emit("most-recent-line-items", newLineItems);
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.setLineItemData();
  },
  mounted() {
    this.autoFocusInput();
    this.expandedRows = [this.lineItems[this.selectedLineItemIndex]];
  },
  emits: [
    "row-edit-save",
    "remove-part",
    "on-row-editing",
    "update:rows",
    "most-recent-line-items",
    "updateLength",
  ],
  methods: {
    autoFocusInput() {
      setTimeout(() => {
        const data =
          this.lineItems[
            this.selectedLineItemIndex == -1
              ? this.selectedLineItemIndex + 1
              : this.selectedLineItemIndex
          ];
        const element = document.getElementById(
          this.getQuantityInputId(data),
        ) as HTMLElement;
        if (element) {
          element.focus();
        }
      }, 0);
    },
    isFieldReadOnly(field: Field) {
      return isFieldReadOnly(field);
    },
    useFractionalQuantities(part: any) {
      return part.fractions === "Y";
    },
    handleDecimals(event: any, part: any) {
      if (this.useFractionalQuantities(part)) {
        Utils.handleLeadingDecimal(event, (value: any) => {
          part.li_order_qtys = value;
        });
      }
    },
    priceUnitOfMeasureSuffix(unitOfMeasure: string) {
      if (unitOfMeasure) {
        return unitOfMeasure;
      }
      return "";
    },
    shouldShowSaveAndResetButtons(rowData: any) {
      return (this.value as any[]).some(
        (prevItem: {
          lis: string;
          li_parts: string;
          li_order_qtys: string | undefined;
          li_prices: string | undefined;
          wrap_desc: string | undefined;
          li_notes: string | undefined;
          custom_fields: any;
        }) => {
          if (
            prevItem.li_parts === rowData.li_parts &&
            prevItem.lis === rowData.lis
          ) {
            return this.compareLineItem(prevItem, rowData);
          }
        },
      );
    },
    updateCustomFieldLineItems() {
      this.lineItems = this.lineItems.map((item: any) => {
        const lineItemCustomFields = {} as any;
        Object.keys(this.lineItemCustomFields).map((field: any) => {
          const name = this.lineItemCustomFields[field].json_name;
          if (name === undefined) {
            return;
          }
          lineItemCustomFields[name] = item[name] ?? "";
        });

        item.custom_fields = lineItemCustomFields;
        return item;
      });
    },
    setCustomFieldData(data: any, lineItem: any, field: Field) {
      const name = field.json_name as string;
      if (field.conv && field.conv[0] === "D") {
        lineItem.custom_fields[name] = Utils.formatDate(data);
        lineItem[name] = Utils.formatDate(data);
      } else if (field.conv && field.conv[0] === "M" && field.conv[1] === "D") {
        lineItem.custom_fields[name] = data.toString();
        lineItem[name] = data.toString();
      } else {
        lineItem.custom_fields[name] = data;
        lineItem[name] = data;
      }
    },
    showPartInventory(event: any, part: any) {
      this.selectedPart = part.li_parts;
      this.showLookupPartNumberDialog = true;
    },
    setLineItemData(lineItemNumber?: string) {
      // if lineItemNumber is passed updates that line item only
      if (lineItemNumber) {
        const index = this.lineItems.findIndex((x) => x.lis === lineItemNumber);
        this.lineItems[index] = JSON.parse(
          JSON.stringify(
            (this.value as any).find(
              (x: { lis: string }) => x.lis === lineItemNumber,
            ),
          ),
        );
      } else {
        this.lineItems = JSON.parse(JSON.stringify(this.value as any)) ?? [];
      }

      this.updateCustomFieldLineItems();
    },
    handleOnHandTagText(availQty: number) {
      if (availQty > 0) {
        return `${availQty}`;
      } else {
        return "Out of Stock";
      }
    },
    handleOnHandTagColor(availQty: number) {
      return availQty > 0 ? "success" : "danger";
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    calculateStep(part: any) {
      if (part.sell_qty) {
        return parseFloat(part.sell_qty);
      }
      return 1;
    },
    handleCancelEdit(rowData: any) {
      this.setLineItemData(rowData.data.lis);
    },
    buttonColor(quantity: number) {
      return quantity > 0
        ? "background-color: var(--default-button-color)"
        : "background-color: grey";
    },
    getQuantityInputId(data: any) {
      return `quantity-input-${data.key}`;
    },
    buildRemovePartMessage(lineItem: any, partNumberLabel: string) {
      const partNumber = lineItem?.li_parts;
      const partDesc = lineItem?.wrap_desc || "";
      return `Are you sure you want to remove this part? \r\n  \r\n${partNumberLabel} ${partNumber}\r\nDescription: ${partDesc}`;
    },
    removePart(event: any, partNumber: string) {
      this.indexToRemove = this.lineItems.findIndex(
        (part: any) => part.li_parts === partNumber,
      );
      this.showRemoveConfirm = true;
    },
    removeEditPart(indexToRemove: number) {
      this.lineItems.splice(indexToRemove, 1);
      this.$emit("remove-part", indexToRemove);
      this.showRemoveConfirm = false;
    },
    removeConfirmMessage(indexToRemove: number): string {
      if (indexToRemove === -1) {
        return "";
      }
      let lineItemsLength = 0;

      if (this.lineItems) {
        lineItemsLength = this.lineItems.length;
      }

      if (lineItemsLength === 0 || indexToRemove >= lineItemsLength) {
        return "";
      }

      return this.buildRemovePartMessage(
        this.lineItems[indexToRemove],
        this.partNumberLabel,
      );
    },
  },
});
