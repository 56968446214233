
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Customer from "@/types/customer";
import Utils from "@/utility/utils";
import CustomerTable from "@/components/Customers/CustomerTable.vue";

export default defineComponent({
  name: "RecentlyViewedCustomers",
  components: {
    CustomerTable,
  },
  computed: {
    ...mapGetters({
      getRecentlyViewedCustomers: "customerInquiry/getRecentlyViewed",
    }),
  },

  methods: {
    ...mapActions({
      removeRecentlyViewedCustomer:
        "customerInquiry/removeRecentlyViewedCustomer",
    }),
    handleCustomersRowClick(e: any) {
      this.$emit("customer-row-click", e);
    },
    handleRemoveCustomer(e: any) {
      this.removeRecentlyViewedCustomer(e);
    },
    shippingAddress(customer: Customer) {
      return Utils.customerShippingAddress(customer);
    },
  },
});
