
import { defineComponent } from "vue";
import { mapActions } from "vuex";

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SalesService from "@/services/SalesService";

//Utilities
import EventBus, { AppEvents } from "@/utility/EventBus";

const salesService = new SalesService();

export default defineComponent({
  name: "UnsavedOrdersDialog",
  components: {
    Button,
    Dialog,
    DataTable,
    Column,
  },
  props: {
    unsavedOrders: {
      type: Array,
      default: () => [],
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUnsavedOrdersDialog: true,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      removeUnsavedOrder: "pos/removeUnsavedOrder",
    }),
    createOrderForUnsavedOrder() {
      if (this.unsavedOrders && this.unsavedOrders.length > 0) {
        this.isLoading = true;
        this.unsavedOrders.forEach((order: any) => {
          let finalOrder = order.order;
          let meta = order.meta;
          salesService
            .postOrder(finalOrder, undefined, meta)
            .then((response) => {
              let saleId = (response as any).recordId;

              if (saleId) {
                this.removeUnsavedOrder(order.id).then(() => {
                  EventBus.emit(AppEvents.PosClearDraftOrders);
                });
              }

              const notification = {
                message: `Successfully Created Order #${saleId}.`,
                type: "success",
              };
              this.addNotification(notification);
            })
            .catch((error) => {
              this.isLoading = false;
              const notification = {
                message:
                  error?.response?.data.error || "Error processing order",
                type: "error",
              };
              this.addNotification(notification);
            })
            .finally(() => {
              this.showUnsavedOrdersDialog = false;
              this.isLoading = false;
            });
        });
      }
    },
  },
});
