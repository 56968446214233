
import { defineComponent, watch } from "vue";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import Card from "primevue/card";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import AddShippingAddressDialog from "@/components/Pos/AddShippingAddressDialog.vue";
import ShipToAddressesTable from "./ShipToAddressesTable.vue";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import Carousel from "primevue/carousel";
import CustomerService from "@/services/CustomerService";
import { ShipItem } from "@/types/customer";
import SalesOrder from "@/types/salesorder";

const customerService = new CustomerService();
import Utils from "@/utility/utils";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ShippingInfo",
  components: {
    ShipToAddressesTable,
    Card,
    AddShippingAddressDialog,
    Button,
    ProgressSpinner,
    CollapsibleSection,
    Carousel,
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      hideUseNewAddressButton: "mrkControl/hideUseNewAddress",
      allowAddressOverride: "mrkControl/allowAddressOverride",
      showShipToId: "mrkControl/showShipId",
      getSalesOrder: "pos/getSalesOrder",
      getClient: "session/getClient",
      getWebAccess: "session/getWebAccess",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
      posShowOnlyShipTo: "mrkControl/posShowOnlyShipTo",
      posAutoCreateCust: "mrkControl/posAutoCreateCust",
    }),
    customerModuleAccess(): boolean {
      return this.getWebAccess("CUST.Q");
    },
    allowAddressChange(): boolean {
      return this.customerModuleAccess || this.allowAddressOverride;
    },
    addresses(): any[] {
      const orderAddress = (this.getSalesOrder as SalesOrder).shipItemAddress;

      const addresses = this.customerAddresses.filter(
        (item: ShipItem) => !orderAddress.compareTo(item),
      );

      return [orderAddress, ...addresses];
    },
    customerAddresses(): ShipItem[] {
      if (!this.getCustomer) {
        return [];
      }
      const customerAddress = Utils.getCustomerMailAddress(this.getCustomer);
      const shipToItems = this.getCustomer.ship_seq_items || [];
      if (this.hideUseNewAddressButton) {
        return [...shipToItems];
      } else {
        return [customerAddress, ...shipToItems];
      }
    },
    maxCarouselPage(): number {
      if (this.addresses === undefined) {
        return 0;
      }

      // Really should only be the second condition, but the first is a workaround since the carousel does not
      // load immediately and the totalIndicators is not available until after the first render
      if (this.$refs.carousel === undefined) {
        return Math.ceil(this.addresses.length / this.scrollLength) - 1;
      } else {
        const totalIndicators = (this.$refs.carousel as any).totalIndicators;
        if (totalIndicators > 1) {
          return totalIndicators - 1;
        } else {
          return 0;
        }
      }
    },
  },
  async created() {
    this.validateSalesOrder({
      metaData: { validate_only: "Y", validate_reason: "CUSTOMER_SELECTED" },
    });
    if (this.getSalesOrder.ship_seq != null) {
      this.getTaxes();
    }
    this.shipAddressIndex = this.addresses.findIndex((address: any) => {
      return address.ship_seq === this.getSalesOrder.ship_seq;
    });

    if (this.shipAddressIndex === -1) {
      this.page = 0;
    } else {
      this.page = Math.floor(this.shipAddressIndex / this.scrollLength);
    }
  },
  data() {
    return {
      shipAddressIndex: -1,
      page: 0,
      scrollLength: 2,
      setFirst: false,
      editMode: false,
      carouselResponsiveOptions: [
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 2,
        },
        {
          breakpoint: "991px",
          numVisible: 3,
          numScroll: 2,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 2,
        },
      ],
      sectionsStatus: {
        addressesInfo: true,
      },
      isLoading: false,
      thirdPartyList: Array<any>(),
      showAddAddressDialog: false,
      showOverrideAddressDialog: false,
      modalHeader: "",
      disabledField: false,
      selectedAddress: {} as ShipItem,
      updateAddressLabel: "",
    };
  },
  methods: {
    ...mapActions({
      getTaxes: "pos/getTaxes",
      setCustomer: "pos/setCustomer",
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    handleOnClose() {
      this.setFirst = true;
    },
    handleUpdatePage(event: any) {
      if (event > this.page) {
        (this.$refs.carousel as any).navForward({}, event);
      } else {
        (this.$refs.carousel as any).navBackward({}, event);
      }

      this.page = event;
    },
    handleRowClick(event: any) {
      this.setFirst = false;
      this.setShipAdr(event.data);
    },
    getSectionIconClass(
      status: { [key: string]: boolean },
      section: "addressesInfo",
    ) {
      return status[section]
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    toggleSectionIcon(tab: "addressesInfo") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    isAddressSelected(data: ShipItem) {
      return (this.getSalesOrder as SalesOrder).shipItemAddress.compareTo(data);
    },
    getCardTitleClass(data: any) {
      if (this.isAddressSelected(data)) {
        return "m-1 selected-address-card shadow-4";
      }
      return "m-1 address-card shadow-4";
    },
    setShipAdr(address: any) {
      const originalOrder = JSON.parse(JSON.stringify(this.getSalesOrder));
      (this.getSalesOrder as SalesOrder).setOrderAddress(
        this.getCustomer,
        address,
        this.getSalesTaxCodes,
      );
      this.getTaxes();
      this.validateSalesOrder({
        oldSalesOrder: originalOrder,
        metaData: {
          validate_only: "Y",
          validate_reason: "SHIP_SEQ",
          validate_id: this.getSalesOrder.ship_seq,
        },
      });
      this.handleUpdatePage(0);
    },
    formattedAddress(data: any) {
      return Utils.customerShippingAddress({
        ship_seq_items: [data],
      } as any).join("\n");
    },
    openAddAddressDialog() {
      this.editMode = false;
      this.disabledField = false;
      this.showAddAddressDialog = true;
      this.modalHeader = "Use New Address";
      this.updateAddressLabel = "Add new address to customer?";
      this.selectedAddress = new ShipItem();
      const shipSeqLength = this.getCustomer.ship_seq_items.length;
      this.selectedAddress.ship_seq = "NEW" + shipSeqLength.toString();
    },
    closeAddressDialog() {
      this.showAddAddressDialog = false;
      this.modalHeader = "";
    },
    editOrderAddress(event: any) {
      event.stopPropagation();
      this.modalHeader = "Order Address";
      this.editMode = false;
      this.disabledField = false;
      this.showAddAddressDialog = true;

      this.selectedAddress = this.getSalesOrder.shipItemAddress;

      if (this.selectedAddress.ship_seq === "CUST") {
        this.updateAddressLabel = "Add address to customer?";
      } else {
        this.updateAddressLabel = "Update customer address?";
      }
    },
    openOverrideAddressDialog(event: any, address: any) {
      event.stopPropagation();
      this.modalHeader = "Update Address";
      this.editMode = true;
      this.disabledField = true;
      this.selectedAddress = cloneDeep(address);
      this.showAddAddressDialog = true;

      if (address.ship_seq === "CUST") {
        this.updateAddressLabel = "Add address to customer?";
      } else {
        this.updateAddressLabel = "Update customer address?";
      }
    },
    async saveNewAddress(data: any, addNewAddress = false) {
      if (addNewAddress) {
        let [oldCust, newCust] = [{}, {}];

        if (!this.getCustomer.ship_seq_items) {
          this.getCustomer.ship_seq_items = [];
        }

        const oldShipSeq = cloneDeep(this.getCustomer.ship_seq_items);
        const newShipSeq = cloneDeep(this.getCustomer.ship_seq_items);

        let nextSeqItemId = (
          this.getCustomer.ship_seq_items
            .map((element: any) => element.ship_seq)
            .reduce((a: any, b: any) => Math.max(a, b), -Infinity) + 1
        ).toString();

        if (nextSeqItemId === "NaN") {
          nextSeqItemId = (
            this.getCustomer.ship_seq_items.length + 1
          ).toString();
        }

        const findIndex = newShipSeq.findIndex(
          (element: any) => element.ship_seq === data.ship_seq,
        );

        if (findIndex >= 0) {
          newShipSeq[findIndex] = data;
        } else {
          newShipSeq.push(data);
        }

        newCust = {
          cust_id: this.getCustomer.cust_id,
          ship_seq_items: newShipSeq,
        };
        oldCust = {
          cust_id: this.getCustomer.cust_id,
          ship_seq_items: oldShipSeq,
        };

        await store
          .dispatch("customerInquiry/updateCustomer", {
            custId: this.getCustomer.cust_id,
            oldCust,
            newCust,
            Client: store.getters["session/getClient"],
            populateBlankMailingAddress: this.posAutoCreateCust ? "Y" : "",
          })
          .then((response) => {
            customerService
              .getCustomer(
                this.getCustomer.cust_id,
                this.getClient,
                "contact_email contact_name",
              )
              .then((response: any) => {
                this.setCustomer({ cust_items: [response] });
                (this.getSalesOrder as SalesOrder).setOrderAddress(
                  this.getCustomer,
                  data,
                  this.getSalesTaxCodes,
                );
              })
              .catch((response) => {
                store.dispatch("notification/add", {
                  message: `An error occurred while getting the customer.`,
                  type: "error",
                });
              });
          })
          .catch((response) => {
            store.dispatch("notification/add", {
              message: `An error occurred while adding the address.`,
              type: "error",
            });
          });
      } else {
        (this.getSalesOrder as SalesOrder).setOrderAddress(
          this.getCustomer,
          data,
          this.getSalesTaxCodes,
        );
      }

      this.getTaxes();
      this.closeAddressDialog();
    },
  },
});
